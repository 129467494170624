import reactjs from "../logos/reactjs.svg"
import js from "../logos/js.svg";
import cpp from "../logos/cpp.svg";
import csharp from "../logos/csharp.svg";
import firebase from "../logos/firebase.svg";
import java from "../logos/java.svg";
import mongodb from "../logos/mongodb.svg";
import mysql from "../logos/mysql.svg";
import nodejs from "../logos/nodejs.svg";
import tailwind from "../logos/tailwind.svg";
import sqlserver from "../logos/sqlserver.svg";
import mongoose from "../logos/mongoose.svg";
import aws from '../logos/aws.svg';
import graphql from '../logos/graphql.png'
import typescript from '../logos/typescript.svg'
import github from '../logos/github-light.png'
import gcp from '../logos/gcp.svg'
import nginx from '../logos/nginx-1.svg'
import linux from '../logos/linux-22621.png'

export default function Skills() {
    return <section id="skills" className="max-w-[90%] lg:max-w-[60%] my-24 mx-auto">
        <h2 className="uppercase text-4xl font-black bg-gradient-to-b from-[#62e3cc] to-[#a3ffe4] bg-clip-text text-transparent text-center lg:mb-20">Skills</h2>        <div className="flex gap-8 flex-wrap text-center justify-center my-8 items-center">
            <img src={js} alt="JS logo" className="h-[100px] w-[100px]"/>
            <img src={nodejs} alt="NodeJS logo" className="h-[100px] w-[100px]" />
            <img src={tailwind} alt="Tailwind logo" className="h-[100px] w-[100px]" />
            <img src={reactjs} alt="ReactJS logo" className="h-[100px] w-[100px]" />
            <img src={typescript} alt="Typescript logo" className="h-[80px] w-[80px]" />
            <img src={nginx} alt="Nginx logo" className="h-[100px] w-[100px]" />
            <img src={graphql} alt="Graphql logo" className="h-[80px] w-[80px]" />
            <img src={mysql} alt="mysql logo" className="h-[100px] w-[100px]" />
            <img src={sqlserver} alt="SQL Server logo" className="h-[100px] w-[100px]" />
            <img src={mongodb} alt="mongodb logo" className="h-[100px] w-[100px]" />
            <img src={mongoose} alt="mongoose logo" className="h-[100px] w-[100px]" />
            <img src={firebase} alt="firebase logo" className="h-[100px] w-[100px]" />
            <img src={java} alt="java logo" className="h-[100px] w-[100px]" />
            <img src={cpp} alt="cpp logo" className="h-[100px] w-[100px]" />
            <img src={csharp} alt="csharp logo" className="h-[100px] w-[100px]" />
            <img src={aws} alt="aws logo" className="h-[100px] w-[100px]" />
            <img src={gcp} alt="gcp logo" className="h-[80px] w-[80px]" />
            <img src={github} alt="github logo" className="h-[80px] w-[80px]" />
            <img src={linux} alt="linux logo" className="h-[100px] w-[100px]" />
        </div>

    </section>
}