export default function ProjectCard({ details }) {
    return (
      <div className="p-4">
        <img
          src={details.image}
          alt=""
          className="rounded-t-lg aspect-video"
        />
        <div className="p-4 bg-[#222222] w-full rounded-b-lg h-[300px] lg:h-[250px] gap-2">
          <p className="text-white py-1">{details.overview}</p>
          <p className="text-white">
            <span className="font-bold">Technologies Used</span>: {details.technologies}
          </p>
          <p>
            Check it out:{" "}
            <a href={details.repoLink} className="underline font-bold">
              {details.name}
            </a>
          </p>
        </div>
      </div>
    );
  }
  