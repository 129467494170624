import sportify from "../sportify.png";
import plant from '../logos/plant.png'
import peekabite from '../logos/Cover.png'
import jamn from "../Welcome.jpg";
import ProjectCard from "./ui-elements/ProjectCard";

const projects = [
    {
        name: "Jamn",
        image: jamn,
        overview: "Where Melodies Meet! A social media platform for musicians to meet other artists and plan potential collaborations.",
        technologies: "Node.js, Express, Firebase, TomTom Maps",
        repoLink: "https://github.com/ishjotsb/Jamn"
    },
    {
        name: "PlanT",
        image: plant,
        overview: "A SaaS platform for landscaping companies to better manage and pitch their services to customers with a native client and inventory management tool.",
        technologies: "Node.js, Express.js, React, MongoDB, TailwindCSS, AWS",
        repoLink: "https://github.com/teamsnorlax/PlanT"
    },
    {
        name: "PeekABite",
        image: peekabite,
        overview: "A mobile application which lets you scan packaged foods to uncover hidden nutrition facts leveraging the use of AI.",
        technologies: "Node.js, React Native, MongoDB, Firebase, AWS, OpenAI, Nginx",
        repoLink: "https://github.com/theteamtitan/PeekABite"
    },
    {
        name: "Sportify",
        image: sportify,
        overview: "Developed a blogging platform for sports fans using multiple multimedia elements to showcase news, discuss games and share thoughts.",
        technologies: "WordPress, Kadence, WordFence, Create Block Theme, Yoast",
        repoLink: "https://github.com/theteamtitan/PeekABite"
    }
]

export default function Projects() {
    return (
        <section className="max-w-[90%] lg:max-w-[60%] my-8 mx-auto text-lg" id="projects">
            <h2 className="uppercase text-4xl font-black bg-gradient-to-b from-[#FF8660] to-[#D5491D] bg-clip-text text-transparent text-center lg:mb-20">Projects</h2>
            <div className="grid lg:grid-cols-2 gap-4 my-16 lg:text-2xl">
                {projects.map(project => <ProjectCard details={project}/>)}
            </div>
        </section>
    );
}
