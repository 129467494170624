import sew from "../logos/robots.png";

export default function Experience() {
    return <section className="max-w-[90%] lg:max-w-[60%] my-0 mx-auto text-lg px-4" id="experience">
        <h2 className="text-center bg-gradient-to-b from-[#5BADFF] to-[#1373D1] bg-clip-text text-transparent uppercase text-4xl font-black lg:mb-20">Experience</h2>
        <div className="my-16 lg:text-2xl">
            <div id="work-1" className="lg:grid lg:grid-cols-[110px_1fr_max-content] items-center gap-6 my-12">
                <img src={sew} alt="SEW Logo" className="w-[100px] h-full py-4"/>
                <p>Product Engineer</p>
                <p>July 2021 - July 2023</p>
                <ul className="col-span-3 list-disc">
                    <li className="my-2">
                        <p>Contributed to the successful development of 4 client based projects, leveraging expertise in languages such as ASP.NET, C#, jQuery, SQL, for which I was awarded the Excellence Award</p>
                    </li>
                    <li className="my-2">
                        <p>Responsible for the development and integration of SalesForce and SAP APIs to 3 client based projects and the base product.</p>
                    </li>
                    <li className="my-2">
                        <p>Revamped the code for the API request methods for Notifications and Payments to increase security and decrease response time.</p>
                    </li>
                    <li className="my-2">
                        <p>Developed and integrated the functionality to allow utility companies to accept POS Payments.</p>
                    </li>
                    <li className="my-2">
                        <p>Tasked with debugging and enhancing the code quality of the legacy system by identifying and rectifying software bugs and inefficiencies.</p>
                    </li>
                </ul>
            </div>
            <div id="work-1" className="lg:grid lg:grid-cols-[110px_1fr_max-content] items-center gap-6">
                <img src={sew} alt="SEW Logo" className="w-[100px] h-full py-4"/>
                <p>Product Engineer - Intern</p>
                <p>January 2021 - June 2021</p>
                <ul className="col-span-3 list-disc">
                    <li className="my-4">
                        <p>Tasked with debugging and resolving reported issues in the codebase and performing ongoing maintenance to ensure code integrity and optimal functionality.</p>
                    </li>
                    <li className="my-4">
                        <p>Enhanced system performance by indentifying and removing redundant database calls, resulting in significant performance increase of the application.</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
}